import { CountryCodeIsoFormatType } from 'containers/account-settings/Atom/country-code.types';
import { configs } from './server-url';

export const ENV_DEVELOPMENT = 'development';

export const BE_HOST_URL = configs.jarvis_url;

export const PLATFORM_HOST_URL = configs.platformHostUrl;

export const CHECKING_SESSION = 'CHECKING_SESSION';

export const INTERNAL_SERVER_ERROR = 'INTERNAL_SERVER_ERROR';

export const STORE_NOTIFICATIONS_DATA = 'STORE_NOTIFICATIONS_DATA';

export const CLEAR_SERVER_ERROR = 'CLEAR_SERVER_ERROR';

export const STORE_PARENT_CATEGORIES = 'STORE_PARENT_CATEGORIES';

export const STORE_EMUNS = 'STORE_EMUNS';

export const GET_ENUMS = 'GET_ENUMS';

export const GET_PARENT_CATEGORIES = 'GET_PARENT_CATEGORIES';

export const LOGGED_OUT = 'LOGGED_OUT';

export const DEV_HOST_ORIGIN = 'https://applocal.turbocomply.com';

export const TOKEN = 'token';
export const ACCOUNT_TOKEN = 'accountToken';
export const SUPER_TOKEN = 'superToken';
export const ROLE = 'role';
export const TENANT_ID = 'tenantID';
export const FIRM_TENANT_ID = 'firmTenant';
export const COMPANY_NAME = 'companyName';
export const COMPANY_LIST = 'companyList';
export const SELECTED_COMPANY = 'selectedCompany';
export const MAX_SIDE_PANEL_COMPANY_LIST = 3;
export const ADJUSTMENTS = 'Adjustments';
export const PROFESSIONAL_TAX_TDS = 'TDS Deducted';
export const GST = 'GST';
export const VAT = 'VAT';
export const ADJUSTMENTS_LABEL = {
  ADJUSTMENTS,
  PROFESSIONAL_TAX_TDS,
  GST,
  VAT,
};

export const CUSTOMER = 'customer';
export const TURBO_ADMINISTRATOR = '1';

export const SESSION_EXPIRY_DAYS = 7;

export const PER_PAGE = 10;
export const CURRENT_PAGE = 1;
export const OTHERS_INDUSTRY_VERTICAL = 16;

interface UrlMappingsType {
  USERS: string;
  API: string;
  V2API: string;
  ADMIN: string;
  AUTH: string;
  SUB_CATEGORIES: string;
  TASKS: string;
  COMPLIANCES: string;
  GENERIC_COMPLIANCES: string;
  CHECKLISTS: string;
  UPLOADS: string;
  COMPANY: string;
  LOCATIONS: string;
}

export const URL_MAPPINGS: UrlMappingsType = {
  USERS: 'api/users',
  API: 'api',
  V2API: 'v2/api',
  ADMIN: 'api/admin',
  AUTH: 'auth',
  SUB_CATEGORIES: 'api/sub_categories',
  TASKS: 'api/tasks',
  COMPLIANCES: 'api/compliances',
  GENERIC_COMPLIANCES: 'api/admin/generic_compliances',
  CHECKLISTS: 'api/task_checklists',
  UPLOADS: 'api/uploads',
  COMPANY: 'api/company',
  LOCATIONS: 'api/locations',
};

export type UserMappingKeysType = keyof UrlMappingsType;
export const QUERY_PARAMS_LOGIN_STEP = 'company-detail';

export const RESTART_ON_REMOUNT = '@@saga-injector/restart-on-remount';
export const DAEMON = '@@saga-injector/daemon';
export const ONCE_TILL_UNMOUNT = '@@saga-injector/once-till-unmount';

export const APPROVED = 2;

export const CENTRAL_LABOUR_LAWS = 'Central Labour Laws';
export const DATE_FORMAT = 'YYYY-MM-DD';
export const LABEL_NO_OF_COLORS = 25;

export const COMPANIES_PER_PAGE = 550;
export const DROPDOWN_COMPANIES_LIMIT = 50;
export const ASC_ORDER = 'asc';
export const DESC_ORDER = 'desc';
export const DROPDOWN_TRIGGER: ('contextMenu' | 'click' | 'hover')[] = [
  'click',
];

export const PATH_SUBSCRIPTION_EXPIRED = '/subscription-expired';
export const PATH_COMPANY_LIST = '/company-list';
export const PATH_ACCOUNT_SETTINGS = '/account-settings';

export const COUNTRY_CODE: Record<string, CountryCodeIsoFormatType> = {
  AE: 'AE',
  IN: 'IN',
  KW: 'KW',
};

export interface LocaleConstantType {
  AE: 'ar-AE';
  IN: 'en-IN';
}

export const LOCALE_CONSTANT: LocaleConstantType = {
  AE: 'ar-AE',
  IN: 'en-IN',
};

export const COUNTRY_CURRENCY: Record<string, string> = {
  AE: 'د.إ.',
  IN: '₹',
};

export const RCFILE_INITIAL_VALUE = { name: '', uid: '' };

export const VAULT_COOKIES = {
  IS_TWO_FACTOR_SETUP_COMMPLETED: 'isTwoFactorSetupCompleted',
  IS_TWO_FACTOR_VERIFIED: 'isTwoFactorVerified',
  IS_OTP_SETUP_COMPLETED: 'isOtpSetupCompleted',
};

export const TAX_COMMODITY: Record<string, string> = {
  AE: 'VAT',
  IN: 'GST',
};

export const PLATFORM_UPDATE_TASK = ['due_date', 'status', 'billable_type'];

export const APP_TABLE_HEIGHT = {
  PERSONAL_FILES: 280,
  FILINGS: 300,
  ALL_TASK: 345,
  OTHER_TASK_SECTION: 280,
  LAWS: 350,
  INVOICE: 270,
  VAULT: 270,
  USER_MANAGEMENT: 290,
  GROUPS: 290,
  ROLES: 290,
  COMPANY_LIST: 270,
  INDIVIDUAL_LIST: 270,
  COMPANY_LIST_HOME: 300,
  INDIVIDUAL_LIST_HOME: 300,
  ALL_TASK_HOME: 300,
  PROFORMA_INVOICE: 270,
  CREDENTIALS_LIST_HOME: 300,
};

export const COMPANIES_DOMAIN_WITHOUT_INDIVIDUAL = [
  1, 2, 3, 4, 5, 6, 7, 8, 10, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110,
  111, 112,
];

export const INDIVIDUAL_COMPANIES_DOMAIN = [9, 999];

export const COMPANY_DOMAIN_WITH_INDIVIDUAL = [
  ...COMPANIES_DOMAIN_WITHOUT_INDIVIDUAL,
  ...INDIVIDUAL_COMPANIES_DOMAIN,
];
